// TODO Work more on this!!

export default function Hero() {
    return (
        <section id='hero'>
            <div class="h-screen w-screen">
                <div className="w-screen h-screen flex items-center justify-center bg-dark">
                    <div class="text-7xl md:text-9xl font-bold relative -top-48">
                        <span class="text-white">
                            Hello!
                        </span>
                    </div>
                </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path fill="#1E1E1E" fill-opacity="1" d="M0,160L48,160C96,160,192,160,288,133.3C384,107,480,53,576,42.7C672,32,768,64,864,96C960,128,1056,160,1152,181.3C1248,203,1344,213,1392,218.7L1440,224L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
            </svg>
        </section>
    );
}
