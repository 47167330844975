import React from "react";

import Hero from './sections/Hero'
import Me from './sections/Me'

export default function App() {
    return (
        <main className="overflow-hidden">
            <Hero />
            <Me />
        </main>
    );
}